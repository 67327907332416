import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import { MailerLiteEmbed } from '../../components/mailerlite-embed';

const EnrollInFreelancingCrashCourse = ({ location }) => {
  const pageTitle = 'Enroll in the Freelancing Crash Course';
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="This free course will teach you to set up your business as a freelance web developer. In a week, youʼll learn to register your business, set up a bank account, and prepare a contract."
        pathname={location.pathname}
      />
      <Article>
        <h1 className="fancy">{pageTitle}</h1>
        <p>
          LLC or S-Corp? What's the best business checking account? What should
          you do for a contract? This course will answer these questions and
          give you everything you need to start freelancing in just a week! I'll
          also share other helpful articles and resources to help you start your
          web development career!
        </p>
        <MailerLiteEmbed
          formUrl="https://dashboard.mailerlite.com/forms/90414/61561404967093637/share"
          height="457px"
        />
      </Article>
    </Layout>
  );
};
export default EnrollInFreelancingCrashCourse;
